const constants = {
  authLabels: {
    name: 'Tappit',
    description: ''
  },
  api_url: process.env.REACT_APP_BASE_URL,
  response: {
    error: 'ERROR',
    unknown_error: 'UNKNOWN_ERROR',
    success: 'OK',
  }
}

export default constants;