/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import constants from '../../../../utils/constants'
import routesMap from "../../../../routesMap";

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-5.jpg")})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}

              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                  <img
                    alt="Logo"
                    className="max-h-50px"
                    src={toAbsoluteUrl("/media/logos/logo.png")}
                  />
                </Link>
                <p className="font-weight-lighter text-white opacity-80">
                  {constants.authLabels.description}
                </p>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; 2024 {constants.authLabels.name}
                </div>
                <div className="d-flex">
                  <Link to={routesMap.terms.path} className="text-white">
                    Privacidad
                  </Link>
                  <Link to={routesMap.terms.path} className="text-white ml-10">
                    Legal
                  </Link>
                  <Link to={routesMap.terms.path} className="text-white ml-10">
                    Contacto
                  </Link>
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path={routesMap.login.path} component={Login} />
                <Redirect from="/auth" exact={true} to={routesMap.login.path} />
                <Redirect to={routesMap.login.path} />
              </Switch>
            </div>
            {/*end::Content body*/}

          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
