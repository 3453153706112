/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import routesMap from '../../../../../routesMap'

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}

        {/* dashboard */}
        <li
          className={`menu-item ${getMenuItemActive(routesMap.dashboard.path, false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">{routesMap.dashboard.name}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}


        {/* SECCION CUENTAS */}

        <li className="menu-section ">
          <h4 className="menu-text">Proveedores</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(routesMap.accounts.path, false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={routesMap.accounts.path}>
            {/* <span className="menu-icon"><i className="fa fa-list"></i></span> */}
            <span className="svg-icon menu-icon">
              <span className="fa fa-folder"></span>
            </span>
            <span className="menu-text">{routesMap.accounts.name}</span>
          </NavLink>
        </li>

        <li
          className={`menu-item menu-item-submenu ${ getMenuItemActive("/user",true) }`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/user">
            <span className="menu-icon">
              <span className="fa fa-users"></span>
              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} /> */}
            </span>
            <span className="menu-text">Usuarios</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Usuarios</span>
                </span>
              </li>

              <li className={`menu-item ${getMenuItemActive(routesMap.user.path)}`} aria-haspopup="true">
                <NavLink className="menu-link" to={routesMap.user.path}>
                  <i className="menu-bullet menu-bullet-dot"> <span /> </i>
                  <span className="menu-text">{routesMap.user.name}</span>
                </NavLink>
              </li>
              
              <li className={`menu-item ${getMenuItemActive(routesMap.userModules.path)}`} aria-haspopup="true">
                <NavLink className="menu-link" to={routesMap.userModules.path}>
                  <i className="menu-bullet menu-bullet-dot"> <span /> </i>
                  <span className="menu-text">{routesMap.userModules.name}</span>
                </NavLink>
              </li>

            </ul>
          </div>

        </li>
        
        {/* SECCION ADMINISTRATIVA */}

        <li className="menu-section ">
          <h4 className="menu-text">Administración</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li className={`menu-item ${getMenuItemActive(routesMap.administrators.path, false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to={routesMap.administrators.path}>
            <span className="svg-icon menu-icon">
              <span className="fa fa-user-secret"></span>
            </span>
            <span className="menu-text">{routesMap.administrators.name}</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive(routesMap.systemEvents.path, false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to={routesMap.systemEvents.path}>
            <span className="svg-icon menu-icon">
              <span className="fa fa-list"></span>
            </span>
            <span className="menu-text">{routesMap.systemEvents.name}</span>
          </NavLink>
        </li>
        {/* ${ getMenuItemActive(routesMap.countries.path,true) } */}
        <li
          className={`menu-item menu-item-submenu `}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <span className="menu-link menu-toggle">
            <span className="menu-icon">
              <span className="fa fa-briefcase"></span>
            </span>
            <span className="menu-text">Catálogos</span>
            <i className="menu-arrow" />
          </span>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Catálogos</span>
                </span>
              </li>
              <li className={`menu-item ${getMenuItemActive(routesMap.countries.path)}`} aria-haspopup="true">
                <NavLink className="menu-link" to={routesMap.countries.path}>
                  <i className="menu-bullet menu-bullet-dot"> <span /> </i>
                  <span className="menu-text">{routesMap.countries.name}</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive(routesMap.states.path)}`} aria-haspopup="true">
                <NavLink className="menu-link" to={routesMap.states.path}>
                  <i className="menu-bullet menu-bullet-dot"> <span /> </i>
                  <span className="menu-text">{routesMap.states.name}</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive(routesMap.municipalities.path)}`} aria-haspopup="true">
                <NavLink className="menu-link" to={routesMap.municipalities.path}>
                  <i className="menu-bullet menu-bullet-dot"> <span /> </i>
                  <span className="menu-text">{routesMap.municipalities.name}</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive(routesMap.localities.path)}`} aria-haspopup="true">
                <NavLink className="menu-link" to={routesMap.localities.path}>
                  <i className="menu-bullet menu-bullet-dot"> <span /> </i>
                  <span className="menu-text">{routesMap.localities.name}</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive(routesMap.activityType.path)}`} aria-haspopup="true">
                <NavLink className="menu-link" to={routesMap.activityType.path}>
                  <i className="menu-bullet menu-bullet-dot"> <span /> </i>
                  <span className="menu-text">{routesMap.activityType.name}</span>
                </NavLink>
              </li>
            </ul>
          </div>

        </li>

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
